import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  LWifi,
  ConceptMedia,
  CFixedImg,
  LStayContact,
  CYoutube,
  CAnchorNav,
  CCard,
  CSpSliderCard02,
} from '../../../../components/_index';
import '../../../../assets/_sass/page/skyresortfloor.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        type="ja"
        size="large"
        data={{
          title: {
            main: <></>,
            sub: '',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/stay/floor/skyresortfloor/kv.png',
              },
              imgSp: {
                src: '/assets/images/stay/floor/skyresortfloor/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'ご宿泊',
                path: '/stay/',
              },
              {
                label: '客室',
                path: '/stay/floor/',
              },
            ],
            current: {
              label: 'スカイリゾートフロア',
            },
          }}
        />
      </CJumbotron>
      <div className="p_skyresortfloor">
        <section className="u_mb90">
          <LWrap>
            <CAnchorNav
              exClass="u_mt90_pc"
              data={[
                {
                  title: <>67F ザ・トップ</>,
                  link: {
                    href: '#topsky',
                  },
                },
                {
                  title: <>65-66F ザ・クラブ</>,
                  link: {
                    href: '#club',
                  },
                },
                {
                  title: <>60-64F アトリエ</>,
                  link: {
                    href: '#sky',
                  },
                },
              ]}
            />
            <p className="c_sectLead u_tal">
              横浜ロイヤルパークホテルは、高層階を「スカイリゾートフロア」としてリニューアルオープンいたしました。
              <br />
              ここは、ワンランク上の滞在をご体験いただける「ホテル イン
              ホテル」。
              <br />
              ラグジュアリーな3つのコンセプトフロアをご用意しております。
              <br />
              ・記念日や特別な日の思い出に、大切な人と過ごす 67階「ザ ・トップ」
              <br />
              ・エグゼクティブゲストのための専用ラウンジを備えた 65-66階「ザ
              ・クラブ」
              <br />
              ・絶景を望むカウンターと、オンリーワンの滞在を楽しむ
              60-64階「アトリエ」
              <br />
              生まれ変わった横浜ロイヤルパークホテルの「スカイリゾートフロア」で、至福のひとときをお過ごしください。
            </p>
            <CYoutube videoId="IyslqBhV2Uc" />
          </LWrap>
        </section>
        <section className="l_sect u_bgWineRed u_colorWhite" id="topsky">
          <LWrap>
            <CSectTitle
              exClass="u_mb40"
              title={{
                en: 'THE TOP',
                ja: '67階 スカイリゾートフロア「ザ・トップ」',
              }}
            />
            <CFixedImg
              exClass="u_mb40_pc u_mb20_sp"
              width={1040}
              img={{
                src: '/assets/images//stay/floor/skyresortfloor/img_top.png',
                alt: '',
              }}
            />
            <p className="c_sectLead u_tal">
              客室最上階、地上263mの眺望を見渡せる、スカイリゾートフロア「ザ・トップ」。
              <br />
              白を基調としたクラシックな内装、優美な猫脚のインテリアと上品なマゼンタカラーをアクセントに、空間全体を柔らかな印象に仕上げました。
              <br />
              明るくエレガントな情景は記念日に華を添え、忘れられないアニバーサリーを演出いたします。
              <br />
              きらめく横浜の夜景を眺めながら、お客様が主役となって輝く特別な一日をお過ごしください。
            </p>
            <h3 className="c_headingLv2 u_mb40">客室タイプ一覧</h3>
            <CCard
              col={3}
              data={[
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_top_room.png',
                    alt: 'コンフォートダブル',
                  },
                  title: <>コンフォートダブル</>,
                  link: {
                    href: '/stay/floor/top_floor/#comfortDouble',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_top_room02.png',
                    alt: 'デラックスダブル',
                  },
                  title: <>デラックスダブル</>,
                  link: {
                    href: '/stay/floor/top_floor/#deluxeDouble',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_top_room03.png',
                    alt: 'プレミアムダブル',
                  },
                  title: <>プレミアムダブル</>,
                  link: {
                    href: '/stay/floor/top_floor/#premiumDouble',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_top_room04.png',
                    alt: 'コンフォートツイン',
                  },
                  title: <>コンフォートツイン</>,
                  link: {
                    href: '/stay/floor/top_floor/#comfortTwin',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_top_room05.png',
                    alt: 'デラックスツイン',
                  },
                  title: <>デラックスツイン</>,
                  link: {
                    href: '/stay/floor/top_floor/#deluxeTwin',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_top_room06.png',
                    alt: 'コーナーツイン',
                  },
                  title: <>コーナーツイン</>,
                  link: {
                    href: '/stay/floor/top_floor/#cornerTwin',
                  },
                },
              ]}
            />
            <h3 className="c_headingLv2 u_mb40">フロアの特徴・サービス</h3>
            <CSpSliderCard02
              exClass="u_mb40"
              col={4}
              data={[
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_top_service.png',
                    alt: '',
                  },
                  title: <>クラシックでエレガントな内装</>,
                  content: (
                    <>
                      やさしく明るい印象のカラースキームに、大人っぽく洗練されたマゼンタをアクセントにしました。クラシックでエレガントなお部屋で特別な時間をお過ごしください。
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_top_service02.png',
                    alt: '',
                  },
                  title: <>ロマン溢れる夜景</>,
                  content: (
                    <>
                      カーテンを開くと眼下に広がる絶景。きらめく夜景を眺める時はカクテルグラスを添えて・・・映画のワンシーンのようなひとときをお楽しみいただけます。
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_top_service03.png',
                    alt: '',
                  },
                  title: <>記念日を祝福</>,
                  content: (
                    <>
                      フラワーブーケをテーマにしたアート作品は、額縁いっぱいに咲き誇る花々がまるで祝福しているかのよう。大切な人と過ごす記念日に華を添えます。
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_top_service04.png',
                    alt: '',
                  },
                  title: <>曲線美のインテリア</>,
                  content: (
                    <>
                      ザ・トップには、猫脚のインテリアを揃えました。しなやかな曲線を描く美しいフォルムが、クラシックでエレガントな空間を際立たせます。
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_top_service05.png',
                    alt: '',
                  },
                  title: <>お部屋で本格コーヒー</>,
                  content: (
                    <>
                      簡単な操作で本格的なコーヒーをお楽しみいただける「ネスプレッソ」のコーヒーマシンを備え付けております。くつろぎのシーンにご利用ください。
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_top_service06.png',
                    alt: '',
                  },
                  title: <>記念日のための非日常空間</>,
                  content: (
                    <>
                      客室最上階に位置するザ・トップは、記念日に最適なアニバーサリールーム。日本で一番高い場所にあるお部屋で、大切な人との思い出を紡いでください。
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_top_service07.png',
                    alt: '',
                  },
                  title: <>細部へのこだわり</>,
                  content: (
                    <>
                      みずみずしいアロマティックな香りが残る「L’OCCITANE」のバスアメニティをご用意しております。
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_top_service08.png',
                    alt: '',
                  },
                  title: <>高精細な迫力映像</>,
                  content: (
                    <>
                      49型4Kテレビを備え付けております。大きな画面で美しい映像をお部屋でゆったりとお楽しみください。
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>
        <section className="l_sect u_bgBlue u_colorWhite" id="club">
          <LWrap>
            <CSectTitle
              exClass="u_mb40"
              title={{
                en: 'THE CLUB',
                ja: '65-66階 スカイリゾートフロア「ザ・クラブ」',
              }}
            />
            <CFixedImg
              exClass="u_mb40_pc u_mb20_sp"
              width={1040}
              img={{
                src: '/assets/images//stay/floor/skyresortfloor/img_club.png',
                alt: '',
              }}
            />
            <p className="c_sectLead u_tal">
              専用ラウンジを備えた、本物志向のエグゼクティブにふさわしいスカイリゾートフロア「ザ・クラブ」。
              <br />
              専任スタッフが常駐し、きめ細やかなサービスをご提供いたします。
              <br />
              モダンスタイルを調和させたインテリア、凛として落ち着きのある空間は、癒しとくつろぎのひとときをお届けいたします。
              <br />
              上質な心地よさに包まれて、最上級のホテルステイをお楽しみください。
            </p>
            <div className="l_sect u_bgDeepBlue u_mb60 p_club_lounge">
              <div className="p_club_lounge_inner">
                <CSectTitle
                  exClass="u_mb40"
                  title={{
                    en: 'CLUB LOUNGE',
                    ja: '「ザ・クラブ」専用のクラブラウンジ',
                  }}
                />
                <p className="c_sectLead u_tal">
                  「ザ
                  ・クラブ」にご宿泊のお客様のみご利用いただける専用ラウンジ。
                  <br />
                  2層吹き抜けの高い天井、横浜の街から富士山まで一望できる広い窓辺が特徴的な、開放感に溢れる空間です。
                  <br />
                  チェックイン・チェックアウトのお手続きをはじめ、ドリンクサービス、フードプレゼンテーションもお楽しみいただけます。
                  <br />
                  とっておきのクラブラウンジで、優雅で贅沢な時間をお過ごしください。
                </p>
                <CFixedImg
                  exClass="u_mb40_pc u_mb20_sp"
                  width={840}
                  img={{
                    src: '/assets/images//stay/floor/skyresortfloor/img_club_lounge.png',
                    alt: '',
                  }}
                />
                <CSpSliderCard02
                  col={2}
                  data={[
                    {
                      img: {
                        src: '/assets/images/stay/floor/skyresortfloor/img_club_lounge02.png',
                        alt: '',
                      },
                      title: <>「スタッフにできること」</>,
                      content: (
                        <>
                          チェックイン、チェックアウトのお手続きの他、コンシェルジュサービスも提供するレセプションデスク。家族のような温かさでお客様に寄り添うことを大切にしてまいります。
                        </>
                      ),
                    },
                    {
                      img: {
                        src: '/assets/images/stay/floor/skyresortfloor/img_club_lounge03.png',
                        alt: '',
                      },
                      title: <>「ティータイムでくつろぎのひととき」</>,
                      content: (
                        <>
                          11:00～15:00は、クッキーなどをご自由にお楽しみいただけます。ドイツ製のコーヒーマシン「WMF
                          」でお好みのコーヒードリンクをお楽しみください。
                        </>
                      ),
                    },
                    {
                      img: {
                        src: '/assets/images/stay/floor/skyresortfloor/img_club_lounge04.png',
                        alt: '',
                      },
                      title: <>「チェックインのあとにアフタヌーンスナック」</>,
                      content: (
                        <>
                          15:00
                          ～18:00は、アフタヌーンティータイム。ケーキやサンドイッチなどの軽食をご用意しております。夕景とともに、優雅でたおやかなひとときをお過ごしください。
                        </>
                      ),
                    },
                    {
                      img: {
                        src: '/assets/images/stay/floor/skyresortfloor/img_club_lounge05.png',
                        alt: '',
                      },
                      title: <>「夜景を見ながらイブニングカクテル」</>,
                      content: (
                        <>
                          18:00 ～21:00（20:30
                          L.O.）は、ビールやソフトドリンクの他に、ワインやカクテルもご自由にお楽しみいただけます。チーズをはじめ、和洋中のオードブル、スナックなどをご用意しております。
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
            <h3 className="c_headingLv2 u_mb40">客室タイプ一覧</h3>
            <CCard
              col={3}
              data={[
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_club_room.png',
                    alt: 'コンフォートダブル',
                  },
                  title: <>コンフォートダブル</>,
                  link: {
                    href: '/stay/floor/club_floor/#comfortDouble',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_club_room02.png',
                    alt: 'コーナーダブル',
                  },
                  title: <>コーナーダブル</>,
                  link: {
                    href: '/stay/floor/club_floor/#cornerDouble',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_club_room03.png',
                    alt: 'コンフォートツイン',
                  },
                  title: <>コンフォートツイン</>,
                  link: {
                    href: '/stay/floor/club_floor/#comfortTwin',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_club_room04.png',
                    alt: 'デラックスツイン',
                  },
                  title: <>デラックスツイン</>,
                  link: {
                    href: '/stay/floor/club_floor/#deluxeTwin',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_club_room05.png',
                    alt: 'コンフォートスイート',
                  },
                  title: <>コンフォートスイート</>,
                  link: {
                    href: '/stay/floor/suite_floor/#comfortSuite',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_club_room06.png',
                    alt: 'エグゼクティブスイート',
                  },
                  title: <>エグゼクティブスイート</>,
                  link: {
                    href: '/stay/floor/suite_floor/#executiveSuite',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_club_room07.png',
                    alt: 'プレジデンシャルスイート',
                  },
                  title: <>プレジデンシャルスイート</>,
                  link: {
                    href: '/stay/floor/suite_floor/#presidentalSuite',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_club_room08.png',
                    alt: '和室スイート',
                  },
                  title: <>和室スイート</>,
                  link: {
                    href: '/stay/floor/suite_floor/#japaneseSuite',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_club_room09.png',
                    alt: 'ロイヤルスイート',
                  },
                  title: <>ロイヤルスイート</>,
                  link: {
                    href: '/stay/floor/suite_floor/#royalSuite',
                  },
                },
              ]}
            />
            <h3 className="c_headingLv2 u_mb40">フロアの特徴・サービス</h3>
            <CSpSliderCard02
              col={4}
              data={[
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_club_service.png',
                    alt: '',
                  },
                  title: <>洗練された空間</>,
                  content: (
                    <>
                      明るく温かみのあるクロスと濃い色のモールディングのコントラストが、シックで洗練された印象を与える客室です。落ち着いた色合いのファブリックも心地良い空間を演出します。
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_club_service02.png',
                    alt: '',
                  },
                  title: <>窓から望む完璧な景色</>,
                  content: (
                    <>
                      方角によってさまざまな表情をお楽しみいただける高層階からの眺望。横浜の街を一望しながらデスクワークも行えます。ここにしかない景色を思う存分ご体験ください。
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_club_service03.png',
                    alt: '',
                  },
                  title: <>アートに親しむ</>,
                  content: (
                    <>
                      お部屋のアクセントとして設置したアート作品は、波の表情をテーマに「動と静」を表現しています。散りばめられた金箔は、日や月のきらめきをイメージしています。
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_club_service04.png',
                    alt: '',
                  },
                  title: <>上質な時を過ごす</>,
                  content: (
                    <>
                      窓際に設置した広いライティングデスク、ゆったりおくつろぎいただけるソファ、お客様が快適にお過ごしいただけるようこだわりました。
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_club_service05.png',
                    alt: '',
                  },
                  title: <>音楽のある空間</>,
                  content: (
                    <>
                      すべてのお部屋に、Bose社製の「Soundlink Revolve Bluetooth
                      Speaker」を完備しております。音の彩りが、記憶に残る一日を演出します。
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_club_service06.png',
                    alt: '',
                  },
                  title: <>細部へのこだわり</>,
                  content: (
                    <>
                      「L’OCCITANE
                      ジャスミン＆ベルガモット」のバスアメニティをご用意しております。ほのかに残る香りは、濃厚ではつらつとした印象です。
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_club_service07.png',
                    alt: '',
                  },
                  title: <>お部屋で本格コーヒー</>,
                  content: (
                    <>
                      簡単な操作で本格的なコーヒーをお楽しみいただける「ネスプレッソ」のコーヒーマシンを備え付けております。くつろぎのシーンにご利用ください。
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_club_service08.png',
                    alt: '',
                  },
                  title: <>高精細な迫力映像</>,
                  content: (
                    <>
                      49型4Kテレビを備え付けております。大きな画面で美しい映像をお部屋でゆったりとお楽しみください。
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>
        <section className="l_sect u_bgOcher u_colorWhite" id="sky">
          <LWrap>
            <CSectTitle
              exClass="u_mb40"
              title={{
                en: 'ATELIER',
                ja: '60-64階 スカイリゾートフロア「アトリエ」',
              }}
            />
            <CFixedImg
              exClass="u_mb40_pc u_mb20_sp"
              width={1040}
              img={{
                src: '/assets/images//stay/floor/skyresortfloor/img_atelier.png',
                alt: '',
              }}
            />
            <p className="c_sectLead u_tal">
              窓際のカウンターからパノラマを望むスカイリゾートフロア「アトリエ」。
              <br />
              ミニチュアの街が宝石のようにきらめく夜景、目の前に広がる季節の彩りをこの特等席から眺めながら、
              <br />
              大切な人とご一緒にお過ごしください。
            </p>
            <h3 className="c_headingLv2 u_mb40">客室タイプ一覧</h3>
            <CCard
              col={3}
              data={[
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_atelier_room.png',
                    alt: 'コンフォートダブル',
                  },
                  title: <>コンフォートダブル</>,
                  link: {
                    href: '/stay/floor/atelier_floor/#comfortDouble',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_atelier_room02.png',
                    alt: 'デラックスダブル',
                  },
                  title: <>デラックスダブル</>,
                  link: {
                    href: '/stay/floor/atelier_floor/#deluxeDouble',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_atelier_room03.png',
                    alt: 'コーナーダブル',
                  },
                  title: <>コーナーダブル</>,
                  link: {
                    href: '/stay/floor/atelier_floor/#cornerDouble',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_atelier_room04.png',
                    alt: 'コンフォートツイン',
                  },
                  title: <>コンフォートツイン</>,
                  link: {
                    href: '/stay/floor/atelier_floor/#comfortTwin',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_atelier_room05.png',
                    alt: 'デラックスツイン',
                  },
                  title: <>デラックスツイン</>,
                  link: {
                    href: '/stay/floor/atelier_floor/#deluxeTwin',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_atelier_room06.png',
                    alt: 'コーナーツイン',
                  },
                  title: <>コーナーツイン</>,
                  link: {
                    href: '/stay/floor/atelier_floor/#cornerTwin',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_atelier_room07.png',
                    alt: 'コンフォートスイート',
                  },
                  title: <>コンフォートスイート</>,
                  link: {
                    href: '/stay/floor/atelier_floor/#comfortSweet',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_atelier_room08.png',
                    alt: 'グランドスイート',
                  },
                  title: <>グランドスイート</>,
                  link: {
                    href: '/stay/floor/atelier_floor/#grandSweet',
                  },
                },
              ]}
            />
            <h3 className="c_headingLv2 u_mb40">フロアの特徴・サービス</h3>
            <CSpSliderCard02
              col={4}
              data={[
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_atelier_service.png',
                    alt: '',
                  },
                  title: <>輝く思い出づくりに</>,
                  content: (
                    <>
                      窓際に設えたカウンターは特等席。目の前に広がる景色を眺めながらお食事もお楽しみいただけます。
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_atelier_service02.png',
                    alt: '',
                  },
                  title: <>迫力ある49型液晶テレビ</>,
                  content: (
                    <>
                      すべてのお部屋に49型の液晶テレビを設置しております。迫力ある映像をお部屋でお楽しみください。
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_atelier_service03.png',
                    alt: '',
                  },
                  title: <>お部屋で本格コーヒー</>,
                  content: (
                    <>
                      コーヒーマシンを完備。くつろぎのひとときをお過ごしください。
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/skyresortfloor/img_atelier_service04.png',
                    alt: '',
                  },
                  title: <>細部へのこだわり</>,
                  content: (
                    <>
                      柑橘系の香りが特徴的な「L’OCCITANE
                      ヴァーベナ」のバスアメニティをご用意しております。
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>
      </div>
      <section className="u_mbMedium">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
      <LStayContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
